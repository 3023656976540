@import "../../variables";

#category-page {
  .card-head {
    border: 0;
    display: flex;
    h2 {
      align-self: flex-end;
      line-height: 35px;
    }
    .head-action {
      @apply w-full;
      display: flex;
      gap: 0.75rem;
      flex-direction: column;

      @media screen and (min-width: $md) {
        flex-direction: row;
      }

      .slc-category-status {
        @apply w-full;
        min-width: 200px;
      }
      .searchpage {
        @apply w-full md:w-auto;
        min-width: 200px;
        height: 100%;
      }
      .input-search {
        @apply border border-gray-400 rounded px-2 focus:outline-blue-400;
      }
      button {
        margin-top: auto;
      }
      .btn-add-category {
        @apply ml-auto;
      }
    }
  }
  .category-tab-section {
    .MuiTabs-scroller {
      overflow: auto !important;
      &::-webkit-scrollbar {
        height: 4px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 px rgba(0, 0, 0, 0.3);
      }
      &::-webkit-scrollbar-thumb {
        @apply bg-gray-200;
      }
    }
    .category-tab-header {
      @apply p-6 flex flex-row gap-2;
      svg {
        @apply my-auto;
      }
    }
    .category-tab-body {
      min-height: 384px;
      padding: 0.75rem;
      &[hidden] {
        padding: 0;
        min-height: 0;
      }

      .item-list {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 1fr;
      }
      &.asColumn .item-list {
        @apply md:grid-cols-2 2xl:grid-cols-3;
        @media screen and (min-width: 1600px) {
          @apply grid-cols-4;
        }
      }
      .category-card-content {
        .box-details {
          @apply text-gray-500;
          display: flex;
          flex-direction: column;
          row-gap: 2px;
          font-size: 0.8rem;
          line-height: 1rem;
          @media screen and (min-width: $md) {
            font-size: 0.75rem;
          }
          .fa-icon {
            font-size: 12px;
            svg {
              width: 1rem;
              padding-right: 5px;
            }
            &:nth-child(2),
            &:nth-child(3) {
              padding-left: 7px;
            }
          }
          .title {
            .id {
              @apply text-red-300 pr-1;
            }
            @apply text-gray-600;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
          }
          .desc {
            @apply mb-1 hidden lg:block;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .cate {
          }
          .display {
          }
          .editor {
          }
        }
      }
    }
  }
}
