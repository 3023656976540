
.loader-spawn {
    &.active {
        height: 200%!important;
        display: block;
        position: relative;
        &::before {
            content: "";
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0 0 0 / 75%);
            z-index: 45;
            animation: fadeIn linear 350ms;
            -webkit-animation: fadeIn linear 350ms;
            -moz-animation: fadeIn linear 350ms;
        }

        @keyframes fadeIn {
            0% {opacity:0;}
            100% {opacity:1;}
          }
          
          @-moz-keyframes fadeIn {
            0% {opacity:0;}
            100% {opacity:1;}
          }
          
          @-webkit-keyframes fadeIn {
            0% {opacity:0;}
            100% {opacity:1;}
          }
          
        .spawn-loading {
            $colors:#fc7171,#FFD454,#a9f4fe,#00e3ff,#7abfff;
            display: flex;
            justify-content: center;
            top: 45%;
            left: 50%;
            z-index: 45;
            transform: translate(-50%, -50%);
            position: fixed;

            .dot {
              position: relative;
              width: 1.5em;
              height: 1.5em;
              margin: 0.65rem;
              border-radius: 50%;
            //   border: 2px solid #22c4d8;
              &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                background: inherit;
                border-radius: inherit;
                animation: wave 930ms ease-out infinite;
              }
          
              @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                  background: nth($colors, $i);
          
                  &::before {
                    animation-delay: $i * 0.2s;
                  }
                }
              }
            }
        }
        
        @keyframes wave {
        50%,
        75% {
            transform: scale(2.5);
        }
        80%,
        100% {
            opacity: 0;
        }
        }
        
    }
}
