@import "../../../variables";
.modal-webinfo-data {
  .modal-custom {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      outline: 0px solid #42424a;
      background-color: #42424a;
    }

    position: absolute;
    top: 20%;
    left: 50%;
    -webkit-transform: translate(-50%, -20%);
    -moz-transform: translate(-50%, -20%);
    -ms-transform: translate(-50%, -20%);
    transform: translate(-50%, -20%);
    width: 90%;
    max-width: 660px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 11px 15px -7px #00000033, 0px 24px 38px 3px #00000024,
      0px 9px 46px 8px #0000001f;
    overflow-y: scroll;
    padding-left: 6px;
    .modal-header {
      @apply mx-4 mt-4 sm:mx-7 sm:mt-7;
      display: flex;
      justify-content: space-between;
      h2 {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        span {
          padding-left: 5px;
        }
      }
    }
    .modal-body {
      @apply mx-4 my-5 sm:mx-7 sm:my-6;

      .modal-fieldset {
        @apply border border-gray-300 px-4 pt-3 pb-6 sm:pb-8 flex flex-col sm:flex-row flex-wrap gap-y-4;
        justify-content: space-between;
        .modal-legend {
          @apply px-1 text-gray-500;
        }
        .modal-content {
          @apply w-full flex gap-y-2 gap-x-2 flex flex-wrap justify-start;
          .input-group {
            @apply w-full flex flex-col;
            .title {
            }
            .inp-text, .inp-textarea {
              @apply w-full border border-gray-300 text-gray-600 focus:outline-blue-400 px-2 py-1 text-sm;
              border-radius: 3px;

              &.inp-error {
                @apply border-2 border-red-300 focus:outline-red-400 ;
              }
            }

            &.half {
              width: 49.5%;
            }
            &.rows {
              @apply flex-row;
            }
          }
          .input-group-action {
            @apply flex items-center  w-full xs:w-1/3 border border-gray-300;
            border-radius: 5px;
            justify-content: space-between;
            padding: 2px 0.75rem;
            margin-top: .5rem;
            .title {
              @apply pl-5 w-28 ;
              user-select: none;
            }
          }
        }
      }
    }
    .modal-footer {
      @apply mx-4 mb-4 sm:mx-7 sm:mb-6 flex;
      column-gap: 0.75rem;
      button {
        font-size: 0.87rem;
        max-height: 2.2rem;
        &.btn-clear {
        }
        &.btn-save,
        &.btn-edit {
          @apply ml-auto;
        }
      }
    }
  }
}
