.custom-btn {
  text-transform: inherit !important;
  padding: 0.45rem!important;
  display: flex;
  align-items: center;
  max-height: 35px;
  font-weight: 300!important;
  svg {
    font-size: 15px !important;
    padding-bottom: 2px !important;
  }
 
  .MuiLoadingButton-loadingIndicator {
    // left: 12px !important;
  } 
  
  &.free {
    min-width: 10px;

  }
  &.xs {
    min-width: 70px;
    font-size: 0.675rem;
    padding: 4px 5px!important;
    svg {
      font-size: 10px !important;
    }
  }
  &.sm {
    min-width: 80px;
  }
  &.md {
    min-width: 110px;
  }
  &.lg {
    min-width: 130px;
  }
  &.xl {
    min-width: 150px;
  }
  &.btn {
    @apply bg-gray-500 hover:bg-gray-600;
  }
  &.btnadd {
    @apply bg-sky-500 hover:bg-sky-600;
  }
  &.btnedit {
    @apply bg-amber-400 hover:bg-amber-400 ;
  }
  &.btndelete {
    @apply bg-rose-600 hover:bg-rose-700;
  }
  &.btncreate {
    @apply bg-blue-500 hover:bg-blue-600;
  }
  &.btnsave {
    @apply bg-teal-500 hover:bg-teal-600;
  }
  &.btncancel {
    @apply bg-gray-500 hover:bg-gray-600;
  }
  &.btnredo {
    @apply bg-gray-300 hover:bg-gray-300 text-gray-600;
  }
}
