@import './../variables';

.main-body {
    transition-duration:  .35s;
    background-color:$bgColor;
    margin-left: 240px;
    min-width: 325px;
    @media screen and (min-width: 375px) {
        min-width: 370px;
    }
    @media screen and (min-width: $sm) {
        min-width: 450px;
    }
    @media screen and (min-width: $xl) {
        margin-left: 15rem;  
    } 
 
    main {
        position: relative;
        z-index: 0;
        padding: 1rem!important;
        @media screen and (min-width: $sm) {
            padding: 1.5rem!important;
        }
    }
    
}

