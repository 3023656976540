@import '../../../variables';

.modal-edit-admin-data {
  .modal-custom {
    &::-webkit-scrollbar {
      width: 6px;
    }
  
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      outline: 0px solid #42424a;
      background-color: #42424a;
    }
  
    position: absolute;
    top: 20%;
    left: 50%;
    -webkit-transform: translate(-50%, -20%);
    -moz-transform: translate(-50%, -20%);
    -ms-transform: translate(-50%, -20%);
    transform: translate(-50%, -20%);
    width: 90%;
    max-width: 550px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 11px 15px -7px #00000033, 0px 24px 38px 3px #00000024, 0px 9px 46px 8px #0000001f;
    overflow-y: scroll;
    padding-left: 6px;
    .modal-header {
      @apply mx-4 mt-4 sm:mx-7 sm:mt-7;
      display: flex;
      justify-content: space-between;
      h2 {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        span {
          padding-left: 5px;
        }
      }
    }
    .modal-body {
      @apply mx-4 my-5 sm:mx-7 sm:my-6;
   
      .modal-fieldset {
        @apply border border-gray-300 pl-4 p-6 flex flex-col sm:flex-row flex-wrap gap-y-6 sm:gap-y-4 ;
        justify-content: space-between;
        .modal-legend {
          @apply px-1 text-gray-500;
        }
        
        .input-group-list {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: .5rem;
          .input-group {
            display: flex;
            width: 100%;
            column-gap: 5px;
            .group-label {
              padding-top: .35rem;
              flex:none;
              text-align: center;  
              width: 100px;
            }
            .inp-text {
              @apply focus:outline-blue-400;
              display: block;
              width: 100%;
              height: calc(2.25rem + 2px);
              padding: 0.375rem 0.75rem;
              font-size: 0.875rem;
              font-weight: 300;
              line-height: 1.5;
              color: #495057;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #ced4da;
              border-radius: 0.25rem;
              box-shadow: inset 0 0 0 #0000;
              transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
              &[disabled] {
                background-color: #f3f3f3;
              }
            }
            .inp-slc {
              @apply focus:outline-blue-400;
              display: block;
              width: 100%;
              height: calc(2.25rem + 2px);
              padding: 0.375rem 0.75rem;
              font-size: .875rem;
              font-weight: 300;
              line-height: 1.5;
              color: #495057;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #ced4da;
              border-radius: 0.25rem;
              box-shadow: inset 0 0 0 #0000;
              transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
            
            .language-body {
              display: flex;
              width: 100%;
              flex-direction: column;
              .language-group {
                @apply border border-gray-300;
                position: relative;
                border-radius: 4px;
                display: flex;
                width: 100%;
                margin-bottom: .5rem;
              
                .checkbox-title {
                  @apply flex justify-center border-r border-gray-300 flex-none items-center;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                  height: 2.1rem;
                  width: 2.5rem;
                  .inp-chk {
                    width: 1rem;
                    height: 1rem;
                  }
                }
                .title {
                  @apply  flex justify-center flex-none items-center;
                  width: calc(100% - 5.5rem);
                  font-weight: 400;
                }
                .abbv {
                  @apply flex justify-center items-center;
                  position: absolute;
                  right: -1px;
                  top: -1px;
                  height: calc(100% + 2px);
                  width: 3rem;
                  font-weight: 400;
                  background-color: #17a2b8;
                  color: #fff;
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                } 
              }
              .language-error {
                @apply opacity-0 text-red-400 text-sm;
              }
              &.error {
                .language-group {
                  @apply border-2 border-red-400;
                  .abbv {
                    @apply bg-red-400;
                  }
                }
                .language-error {
                  opacity: 1;
                }
              }
           
            }
          }
        }
      }
    }
    .modal-footer {
      @apply mx-4 mb-4 sm:mx-7 sm:mb-6 flex;
      column-gap: .75rem;
      button {
        font-size: .87rem;
        max-height: 2.2rem;
        &.btn-clear {
        
        }
        &.btn-save, &.btn-edit {
          @apply ml-auto;
        }
      }
     
    }
  }
}

