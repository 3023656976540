$xs: 450px;
$sm: 650px;
$md: 768px;
$lg: 900px;
$xl: 1200px;
$xxl: 1440px;
$bgColor: #f3f4f6;
$bgDark: #343a40;
$bgBrownDark: #191919;
$borderDark: #494e53;
$lightTeal: #17a2b8;