@import "../../variables";
#webinfo-page {
  @apply mb-4;
  .card-head {
    display: flex;
    h2 {
      @apply hidden lg:block;
      align-self: flex-end;
      line-height: 35px;
    }
    .head-action {
      @apply w-full sm:w-auto;
      display: flex;
      gap: 0.75rem;
      flex-direction: column;

      @media screen and (min-width: $md) {
        flex-direction: row;
      }

      .slc-webinfo-status {
        @apply w-full;
        min-width: 200px;
      }
      .searchpage {
        @apply w-full md:w-auto;
        min-width: 200px;
        height: 100%;
        margin-left: auto;
      }
      .input-search {
        @apply border border-gray-400 rounded px-2 focus:outline-blue-400;
      }
      button {
        margin-top: auto;
      }
    }
  }
  .card-body {
    @apply px-4 sm:px-5 overflow-hidden;
    .webinfo-tab-box {
      @apply flex-col lg:flex-row lg:gap-4;
      .MuiTabs-root {
        @apply border border-gray-200 lg:border-0 lg:border-r;
        min-width: 200px;

        .webinfo-tab-head-title {
            display: flex;
            flex-direction: row;
            column-gap: 6px;
            justify-content: start;
            font-size: .875rem;
            min-height: 3.25rem;
            font-weight: 500;
            svg {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        
      }

      .table-container {
        // min-width: 758px;
        // overflow: auto;

        &::-webkit-scrollbar {
          width: 0;
          height: 8px;

        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
        }
         
        &::-webkit-scrollbar-thumb {
          background-color: $borderDark;
        }
         
        thead {
          tr {
            height: 3rem;
            .table-th {
              @apply bg-gray-400 text-white;
              border-right: 1px solid ;
              border-color: #e0e0e0;
              &:last-child {
                border-right: 0;
              }

              &.head-title {
                min-width: 150px;
              }
              &.head-value {
                min-width: 250px;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              border-right: 1px solid;
              border-color: #e0e0e0;
              &:last-child {
                border-right: 0;
              }
              .btn-link {
                @apply hover:text-blue-600;
                cursor: pointer;
                font-size: .875rem;
                font-family:  sans-serif;
                color: #007bff;
                padding: 0;
              }
              a {
                text-decoration: underline;
              }
            }
          }
        }
      
        .webinfo-table {
            td {
              word-break: break-all;
            }
            .blog-action {
                display: flex;
                column-gap: 5px;
            }
        }
        }

      .not-found {
        @apply text-gray-600 pt-2 text-lg; 
      }
    
      .tab-body {
        @apply py-4 lg:py-0 w-full;
        @media screen and (max-width: $xl) {
          overflow: auto;
        }
      }

      .web-info-detail {
        @apply flex flex-col gap-y-3 lg:gap-y-4 xl:gap-y-5;
        .blog-control {
          @apply flex flex-col xl:flex-row;
          label {
            @apply flex-none w-44 xl:text-center;
            line-height: 2.25rem
          }
          .upload-image-group {
            @apply flex flex-wrap gap-2 xl:gap-3;
            .group {
              width: 125px;
              @media screen and (min-width: $xxl) {
                width: 140px;
              }
              .image-upload {
                @apply shadow-md bg-white;
                width: 100%;
                border:1px solid;
                border-radius: 5px;
                border-color: #6d71752b;
                position: relative;  
                height: 135px;
                @media screen and (min-width: $xxl) {
                  height: 145px;
                }
                &:hover {
                  &::after {
                    content: "";
                    border:1px solid;
                    border-color: rgba(0, 0, 0, .1);
                    border-radius: 5px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
                  }
                  img {
                    border-radius: 3px;
                    @media screen and (min-width: $lg) {
                      transform: scale(0.93)!important;
                    }
                  }
                }
                img {
                  pointer-events: none;
                  transition-duration: .75s;
                  transform: scale(1);
                  width: 100%;
                  height: 100%;
                  border-radius: 4px;
                  background: #e9e9e9;
                  object-fit: cover;
                  &.small-image {
                    width: auto;
                    height: auto;
                  }
                }
                .image-icon {
                  position: absolute;
                  color: $borderDark;
                  display: flex;
                  font-size: 2rem;
                  opacity: .75;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                }
                .inp-file {
                  cursor: pointer;
                  position: absolute;
                  left: 0;
                  top: 0;
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                }
               
                .upload-icon {
                  display: flex;
                  height: 100%;
                  align-items: center;
                  justify-content: center;
                  svg {
                    @apply text-gray-400;
                    font-size: 2rem;
                  }
                }

              }
             
            }
            .image-label {
              padding: 2px 3px;
              display: flex;
              justify-content: space-between; 
              label {
                @apply text-gray-500 text-sm;
              }
              .btn-remove-image {
                @apply text-red-500 flex items-center;
                svg {
                  font-size: .75rem;
                }
              }
            }
          }

          &:last-child {
            .input-group {
              padding-bottom: .5rem;
              padding-right: .5rem;
              padding-left: .15rem;
            }
          }
        }
        .upload-images {
          label {
            font-weight: 400;
          }
        }
        .website-name {
          label {
            font-weight: 400;
          }
          .input-group {
            @apply w-full flex gap-y-2 flex-wrap justify-between;
            max-width: $lg;
            .inp {
              @apply w-full border border-gray-300 px-2 py-1 focus:outline-blue-400;
              border-radius: 3px;
              &.inp-error {
                @apply border-2 border-red-300 focus:outline-red-400 ;
              }
              &.half {
                @media screen and (min-width: $sm) {
                  width: 49.25%;
                }
                @media screen and (min-width: $xxl) {
                  width: 49.5%;
                }
              }
            } 
          }
        }
      }
    }
  }
}

.modal-preview {
  @apply px-4;
  top: 10%!important;
  margin: auto;
  max-width: 1024px;
  width: 100%;
  .modal-preview-body {
    @apply p-4 md:p-8 md:pb-6;
    background: #fff;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    h2 {
      @apply text-gray-700 mb-4;
      font-size: 1.5rem;
      font-weight: 400;
    }
    iframe {
      width: 100%!important;
    }
    .btn-close {
      margin-left: auto;
      margin-top: 1.25rem;
    }
  }
}