@import '../../../variables';

.modal-custom {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    outline: 0px solid #42424a;
    background-color: #42424a;
  }

  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translate(-50%, -20%);
  -moz-transform: translate(-50%, -20%);
  -ms-transform: translate(-50%, -20%);
  transform: translate(-50%, -20%);
  width: 90%;
  max-width: 660px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px #00000033, 0px 24px 38px 3px #00000024, 0px 9px 46px 8px #0000001f;
  overflow-y: auto;
  // max-height: 95vh;
  // height: 100%;
  padding-left: 6px;
  .modal-header {
    @apply mx-3 mt-4 sm:mx-7 sm:mt-7;
    display: flex;
    justify-content: space-between;
    h2 {
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      span {
        padding-left: 5px;
      }
    }
    .param-generator {
      @apply text-gray-300 hover:text-red-400;
    }
  }
  .modal-body {
    @apply mx-3 my-4 sm:mx-7 sm:my-6;
    overflow: auto;
    max-height: calc(100vh - 200px);
    height: 100%;
    .modal-fieldset {
      @apply border border-gray-300 px-4 pt-4 pb-6  sm:pt-4 sm:pb-8 flex flex-col sm:flex-row flex-wrap gap-y-6 sm:gap-y-4 ;
      justify-content: space-between;
      .modal-legend {
        @apply px-1 text-gray-500;
      }
      .input-group {
        position: relative;
        width: 100%;
        max-width: 100%;
        &.half {
          max-width: 100%;
          @media screen and (min-width: $sm) {
            max-width: 49%;
          }
        }

        .inp {
          width: 100%;

          
          input {
            @apply text-gray-400;
            line-height: 1.5rem!important;
            height: 1.5rem!important;
            font-size: .875rem!important;
            &:focus {
              @apply text-gray-700;
            }
          }
        }
        .param-copy {
          padding: 0.5rem .875rem;
          max-width: 20px;
          z-index: 10;
          position: absolute;
          top: 50%;
          right: 25px;
          transform: translate(0, -25%);
        }
        .param-generator {
          z-index: 10;
          padding: 0.5rem .875rem;
          max-width: 20px;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -25%);
        }
      }
    }
  }
  .modal-footer {
    @apply mx-3 mb-4 sm:mx-7 sm:mb-6 flex;
    column-gap: .75rem;
    button {
      font-size: .87rem;
      max-height: 2.2rem;
      &.btn-clear {
      
      }
      &.btn-create, &.btn-edit {
        @apply ml-auto;
      }
    }
  }
}
